import { CircularProgress, CircularProgressProps } from "@mui/material";
import * as React from "react";

const Loader: React.FC<CircularProgressProps> = (props) => {
  return (
    <CircularProgress
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      {...props}
    />
  );
};

export default Loader;
