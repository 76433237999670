import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://front-end-api.amihub.tech",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.data.details ===
        "Access token error, please login or try again" ||
      error.response.data.details === "Access token missing"
    ) {
      localStorage.clear();
      window.location.reload();
    }
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
