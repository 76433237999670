import * as React from "react";
import styled from "@emotion/styled";
import { CircularProgress, Divider, IconButton, InputBase, Paper } from "@mui/material";
import { Search as SearchIcon } from "react-feather";

const InputWrapper = styled(Paper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 550;
  border: 1px solid gray;
`;

type SearchInputProps = {
  value: string;
  handleChange: (val: string) => void;
  handleSearch: () => void;
  placeholder?: string;
  loading? : boolean;
};

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "",
  value = "",
  handleChange,
  handleSearch,
  loading
}) => {
  return (
    <InputWrapper>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton disabled={loading} onClick={handleSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
        {loading ? <CircularProgress size={24} /> : <SearchIcon />}
      </IconButton>
    </InputWrapper>
  );
};

export default SearchInput;
