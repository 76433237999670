import GroupIcon from "@mui/icons-material/Group";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { SidebarItemsType } from "../../types/sidebar";
import { RequestPageOutlined } from "@mui/icons-material";

const pagesSection = [
  {
    href: "/accounts",
    icon: GroupIcon,
    title: "Аккаунты",
    // children: [],
  },
  {
    href: "/projects",
    icon: BusinessCenterIcon,
    title: "Проекты",
    // children: [],
  },
  {
    href: "/billingRequests",
    icon: RequestPageOutlined,
    title: "Запросы тарифа",
    // children: [],
  },
] as unknown as SidebarItemsType[];
const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
