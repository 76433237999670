import async from "./components/Async";

import Login from "./pages/auth/Login";

import AuthLayout from "./layout/Auth";
import Layout from "./layout/Layout";

import { RouteObject } from "react-router-dom";
import AuthGuard from "./components/guards/AuthGuard";
import Page404 from "./pages/auth/Page404";
import GuestGuard from "./components/guards/GuestGuard";

const Iframe  = async(() => import("./pages/iframe"))
const AccountDetails = async(() => import("./pages/accounts/AccountDetails"));
const ProjectDetails = async(() => import("./pages/projects/PojectDetails"));
const Accounts = async(() => import("./pages/accounts/Accounts"));
const Projects = async(() => import("./pages/projects/Projects"));
const BillingRequests = async(() => import("./pages/billing"));


type routeType = {
  name: string;
  path: string;
  element: React.ReactElement;
};

const privateRoutes: routeType[] = [
  {
    name: 'Account Details',
    path: '/accounts/:accountId',
    element: <AccountDetails />
  },
  {
    name: 'Project Details',
    path: '/projects/:projectId',
    element: <ProjectDetails />
  },
  {
    name: "Accounts",
    path: "/accounts",
    element: <Accounts />,

  },
  {
    name: "Projects",
    path: "/projects",
    element: <Projects />,
  },
  {
    name: 'Attribution',
    path: '/iframe/attribution',
    element: <Iframe iframeName="attribution" />
  },
  {
    name: 'Prediction',
    path: '/iframe/prediction',
    element: <Iframe iframeName="prediction" />
  },
  {
    name: 'Goal',
    path: '/iframe/goal-info',
    element: <Iframe iframeName="goal-info" />
  },
  {
    name: 'Last-Events',
    path: '/iframe/last-events',
    element: <Iframe iframeName="last-events" />
  },
  {
    name: 'Goal',
    path: '/billingRequests',
    element: <BillingRequests />
  }
];

const routes: RouteObject[] = [
  {
    path: "login",
    element: (
      <GuestGuard>
        <AuthLayout children={<Login />} />
      </GuestGuard>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Layout children={undefined} />
      </AuthGuard>
    ),
    children: privateRoutes,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
