import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import SearchInput from "./SearchInput";
import companyApi from "../../services/company";
import { useNavigate } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const [accauntValue, setAccauntValue] = React.useState("");
  const [companyValue, setCompanyValue] = React.useState("");
  const [accountLoading, setAccountLoading] = React.useState(false);
  const [companyLoading, setCompanyLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleAccountSearch = async () => {
    if (accauntValue.trim() === "") return;
    const data = { user_email: accauntValue };
    try {
      setAccountLoading(true);
      const res = await companyApi.findCompany(data);
      setAccountLoading(false);
      navigate("/accounts", { state: res.data.data, replace: true});
    } catch (err) {
      console.log(err);

      setAccountLoading(false);
    }
  };

  const handleCompanySearch = async () => {
    if (companyValue.trim() === "") return;
    const data = { domain: companyValue };
    try {
      setCompanyLoading(true);
      const res = await companyApi.findCompany(data);
      setCompanyLoading(false);
      navigate("/projects", { state: res.data.data, replace: true,  });
      
    } catch (err) {
      console.log(err);

      setCompanyLoading(false);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <SearchInput
                loading={accountLoading}
                value={accauntValue}
                handleChange={(val) => setAccauntValue(val)}
                handleSearch={() => handleAccountSearch()}
                placeholder={"Поиск аккаунта"}
              />
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <SearchInput
                loading={companyLoading}
                value={companyValue}
                handleChange={(val) => setCompanyValue(val)}
                handleSearch={() => handleCompanySearch()}
                placeholder={"Поиск проекта"}
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
