// import { Counter } from './features/counter/Counter';
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useRoutes } from "react-router-dom";
import { AuthProvider } from "./contexts/JWTContext";
import useTheme from "./hooks/useTheme";
import routes from "./routes";
import createTheme from "./theme";

function App() {
  const content = useRoutes(routes)
  const { theme } = useTheme();
  
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <AuthProvider>
        {content}
      </AuthProvider>
    </MuiThemeProvider>
  );
}

export default App;
