import axiosInstance from "../utils/axios";

type findCompanyDataType = {
  user_email?: string;
  domain?: string;
};

type fullInfoDataType = {
  company_id: string;
  project_id?: string;
};

const findCompany = (data: findCompanyDataType) => {
  return axiosInstance.post("/company/find", { data });
};

const getCompanyFullInfo = (data: fullInfoDataType) => {
  return axiosInstance.post("/company/full-info", { data });
};

const companyApi = {
  findCompany,
  getCompanyFullInfo,
};

export default companyApi;
