import React from "react";
import styled from "@emotion/styled";

import { Button, Paper, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// const BigAvatar = styled(Avatar)`
//   width: 92px;
//   height: 92px;
//   text-align: center;
//   margin: 0 auto ${(props) => props.theme.spacing(5)};
// `;

function Login() {
  const { signIn } = useAuth();
  const navigate = useNavigate()  
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {"Админ панель"}
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .max(255)
              .required("Требуется имя пользователя"),
            password: Yup.string().max(255).required("Пароль требуется"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await signIn(values.email, values.password);
    
              navigate("/");
            } catch (error: any) {
              const message = error.message || "Something went wrong";
    
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                margin="dense"
                type="text"
                name="email"
                label={"Email"}
                value={values.email}
                variant="filled"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                type="password"
                name="password"
                label={"Пароль"}
                value={values.password}
                variant="filled"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                sx={{ mb: 2, mt: 2 }}
              >
                {"Войти"}
              </Button>
            </form>
          )}
        </Formik>
      </Wrapper>
    </React.Fragment>
  );
}

export default Login;
