import * as React from "react";
import styled from "@emotion/styled";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  IconButton as MuiIconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSignOut: () => {};
}

const DialogExit: React.FC<DialogProps> = ({ open, handleClose, handleSignOut }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography component="div" textAlign="center">
          {"Log Out"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="div" textAlign="center">
            {"Do you really want to log out"}?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        pb={3}
      >
        <Button
          autoFocus
          onClick={handleSignOut}
          color="primary"
          variant="contained"
        >
          {"Yes"}
        </Button>
        <Button onClick={handleClose} color="inherit" variant="contained">
          {"No"}
        </Button>
      </Stack>
    </Dialog>
  );
}

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <React.Fragment>
      <DialogExit
        handleClose={handleClose}
        handleSignOut={handleSignOut}
        open={open}
      />
      <Tooltip title={"Выйти"}>
        <IconButton
          aria-haspopup="true"
          onClick={handleClickOpen}
          color="inherit"
          size="large"
        >
          <LoginIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
